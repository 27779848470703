/* eslint-disable @tencent/tea-i18n/no-bare-zh-in-js */

/**
 * @type {Window}
 */
gconfig = {
  COS_CONFIG: {
    PUBLIC: 'tanlive-public-1310777349',
    PUBLIC_URL: 'https://tanlive-public-1310777349.cos.ap-guangzhou.myqcloud.com',
    PRIVATE: 'tanlive-private-1310777349',
    PRIVATE_URL: 'tanlive-private-1310777349.cos.ap-guangzhou.myqcloud.com',
    CDN_URL: 'https://file.tanlive.com',
    CDN_HELP_CENTER: 'https://file.tanlive.com/helpcenterdoc/livedoc/',
    REGION: 'ap-guangzhou',
    REGION_ABROAD: 'ap-singapore',
    PUBLIC_ABROAD: 'tanlive-abd-public-1310777349',
    PUBLIC_URL_ABROAD: 'https://tanlive-abd-public-1310777349.cos.ap-singapore.myqcloud.com',
    PRIVATE_ABROAD: '',
    PRIVATE_URL_ABROAD: '',
    EXCEL_TEMPLATE: {
      zh: {
        team: '/tmpl/碳LIVE创新库团队导入模板 v1.6.1.xlsx', // 这里名字要和桶里对应
        product: '/tmpl/碳LIVE创新库产品技术导入模板 v1.6.1.xlsx',
      },
      en: {
        team: '/tmpl/TanLIVE Innovators Import Template v1.6.1.xlsx',
        product: '/tmpl/TanLIVE Solutions Import Template v1.6.1.xlsx',
      },
    },
  },
  RUM_CONFIG: {
    enable: true,
    APPID: 'PJj5gs1xK382q6Ym77'
  },
  // 碳live公众号 扫码关注公众号登录
  WX_CONFIG: {
    enable: true,
    APPID: 'wx1f38a22967ca7692',
    WX_REGISTER_GET_USERINFO_URL: 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1f38a22967ca7692&redirect_uri=https%3A%2F%2Ftanlive.com%2Fthird-signup%2Fwechat&response_type=code&scope=snsapi_userinfo&state=${{id}}#wechat_redirect',
    LOGIN_URL: 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1f38a22967ca7692&redirect_uri=https%3A%2F%2Ftanlive.com%2Fthird-login%2Fwechat&response_type=code&scope=snsapi_base&state=login#wechat_redirect',
    BIND_URL: 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1f38a22967ca7692&redirect_uri=https%3A%2F%2Ftanlive.com%2Fconsole%2Faccount-indv&response_type=code&scope=snsapi_base&state=login#wechat_redirect',
  },
  // 碳live开放平台，第三方应用，团队认证微信扫码
  WX_TEAM_CONFIG: {
    enable: true,
    APPID: 'wxac1cf2fe89884144'
  },

  CAPTCHA_CONFIG: {
    enable: true,
    APPID: '194878912',
  },
  PUBLIC_KEY: `-----BEGIN rsa public key-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA1xbo3kLi7ujcKeXotr1c
uM1IBDFfpURBjKsi8WqhY165Fz2Jt2JjQzfA9he+/5e1rnhenUkB3uhFqey+sdlL
kyEeRq+AJCmbt9SvDa4EtjdrX+KB0c/S+jq3kWyDrUtizIF8OHAEoH9585LByCCW
IF/aDDCJB9O1CyJXLJXz9h6Q4nibNHtqODN6yrt2563Qqzkg0UVXql4g6I7iZK+j
m2js2t1aaSjkNg3qYWmhkgbtdSA+Tt0I47uZxcPTt3qVAWvKSG0jXFrNbovcSNlF
f/VABwuvB4tXrDj1DCxCjq1pu4oBywOXH8svSTMNsp8vNqR7GxuX85mss5AZZQcw
yE7GSAvd6krWvIc1sUuF2atGeT/GvclbWn+UEzUOxpjrdGw1hGxerfiqSf5MndRc
MF2uDdxXdh94IjkBmnCMyzneZhAwm3KoCtfETTN+MWFRIh1WKtevgGZBic6+2THN
u8inX06UhLtfLJQUY0euy8qwx3rnXKktoyXcbMdt+wrJripsHxufy4jwciYqoBCK
WcIV9SNLglKBZP3i2xNJ2TDTAkQzI4n8Z6DWG2z8DohuQXL2bplJzeYo9Bcr5yR1
cv/sxxJRh8ebkBROke9eplKcN/YHLgNy+512VldJklIz0uBoYgO4UAacRIbUFwRG
44bJEBPvxl6j/NDXe0p4lGUCAwEAAQ==
-----END rsa public key-----`,
  LOGIN_URL: 'https://tanlive-idp.cig.tencentcs.com/sso/tn-fb24ab7b259b4ca09c918b14d328fbb9/ai-968a2f9f3676411799bb3a21d8cee512/oidc/authorize?redirect_uri=https://carbonxprogram.com/api/iam/oauth2/link_callback4&client_id=ai-968a2f9f3676411799bb3a21d8cee512&scope=all&response_type=code&from=idp',
  LOGOUT_URL: 'https://tanlive.com/logout?redirect=https://carbonxprogram.com/logout?redirect=https://tanlive.cig.tencentcs.com/logout?return_to=https://tanlive.com/',
  MY_ENERGY: '',
  // 能见授权地址
  NJ_AUTH: 'https://ucenter.nengapp.com/login?clientId=63fe52544f7153a2d23339fec7638e272d6323c6&redirectUrl=https%3A%2F%2Ftanlive.com%2Fthird-login',
  // 能见网站域名
  NJ_DOMAIN: 'https://ucenter.nengapp.com/',
  // PNP授权地址
  PNP_AUTH: 'https://playbook.pnpchina.com/#/OtherLogin/oauth2login?response_type=code&redirect_uri=https%3A%2F%2Ftanlive.com%2Fthird-login&client_id=0ebe8506-239a-856d-9b10-140d3c6a141e',
  // PNP网站域名
  PNP_DOMAIN: 'https://playbook.pnpchina.com/',
  CURRENT_DOMAIN: 'https://tanlive.com',
  CURRENT_PROGRAM_DOMAIN: 'https://carbonxprogram.com',
  HELP_CENTER_DEFAULT_DOC_ID: ['187', '249'],
  ECS_AUTH: 'https://idaas-saas-idp.enerlink.tencent.com/sso/tn-a4b3b4cda05c4accaaf41cf00bf58407/ai-f7ccc44d7e144f338977bf555659844d/oidc/authorize?redirect_uri=https://tanlive.com/third-login?from=ecs&client_id=ai-f7ccc44d7e144f338977bf555659844d&scope=all&response_type=code&from=idp',
  CARBON_SEARCH_ID: 904,
  RECOURCES: [],
  TMAP_KEY: 'ZS7BZ-TQEEJ-CMLF5-XFOJX-5MGP2-JOBK5',
  // 举报问卷
  REPORT_WJ: {
    'en': 'https://wj.qq.com/s2/13694991/d48e/',
    'zh': 'https://wj.qq.com/s2/13695054/5eb2/'
  },
  BEACON_APPKEY: '0WEB05P1065M7U6Q',
  VERIFY_CODE_WHITE_ACCOUNTS: ['984f9f29026bd200c01e45a65134ec42','88d00ee23c3142ab99a6f2960bc49ac3','c8d032edf25995ddffb6f66939a4eb98']
};

const isCarbon = () => {
  const carbonUrls = [
    'carbonxprogram.com',
    'www.carbonxprogram.com',
    'pre.carbonxprogram.com',
    'test.carbonxprogram.woa.com',
    'dev.carbonxprogram.woa.com',
  ];
  const { host } = location;
  return carbonUrls.includes(host);
};
if (isCarbon()) {
  gconfig.RUM_CONFIG = {
    enable: true,
    APPID: '0WEB05P1065M7U6Q',
  };
}
